<template>
  <div class="ConventionHonoraires">


    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="black">
    </loading>

    <CToaster :autohide="3000">
      <template v-for="toast in savedConventionToasts">
        <CToast
          :key="'toast' + toast"
          :show="true"
        >
        <template #default>
          <strong class="text-success">Convention sauvegardée 👍</strong>
        </template>
        </CToast>
      </template>
    </CToaster>

    <CRow>
      <CCol sm="8">
        <h1> Editez votre convention d'honoraires - {{convention.mission.client.name}} </h1>
      </CCol>
      <CCol class="text-right align-self-center" sm="4">
        <CButton
          @click="$router.push('/mission/' + convention.mission.id)"
          shape="pill"
          size="sm"
          color="outline-primary">
            <CIcon name="cil-arrow-left"/> Retour au tableau de bord mission
        </CButton>
      </CCol>
    </CRow>

    <CRow>
      <CCol md="8">
        <p>
          <strong>Cette convention est automatiquement à partir de la convention type de votre cabinet.</strong>
          Vous pouvez éditer directement en ligne chaque partie de votre convention d'honoraires puis la télécharger directement en pdf
        </p>
        <p style="font-size: 1.1em;">
          <strong class="text-danger"><CIcon name="cil-warning" />
            Attention</strong><br>
            <strong><span style="background-color: rgb(255,255,0)">XXX</span>: à modifier par vous-même </strong>.
         </p>
      </CCol>
      <CCol md="4">
        <p>
          <strong><u>Légende</u></strong>
        </p>
        <p>
          <CIcon class="text-primary" size="lg" name="cil-save"/> : Enregistrer l'article<br>
          <CIcon class="text-dark" size="lg" name="cil-arrow-circle-top"/> : Décaler l'article d'un cran vers le haut<br>
          <CIcon class="text-dark" size="lg" name="cil-arrow-circle-bottom"/> : Décaler l'article d'un cran vers le bas<br>
          <CIcon class="text-danger" size="lg" name="cil-trash"/> : Supprimer l'article
        </p>
      </CCol>
    </CRow>


    <CRow class="mt-2">
      <CCol class="text-right">
        <CButton color="outline-primary" @click="downloadConvention" shape="pill"><CIcon name="cil-cloud-download" /> Télécharger votre convention en pdf</CButton>
      </CCol>
    </CRow>


    <div class="mt-2">
      <CRow>
        <CCol>
          <h2 class="convention-title">Introduction</h2>
        </CCol>
        <CCol class="text-right align-self-center">
          <CButton
            v-c-tooltip="'Enregistrer'"
            square
            @click="modifyConventionHonoraires">
            <CIcon class="text-primary" size="lg" name="cil-save"/>
          </CButton>
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <vue-editor v-model="convention.introduction" :editor-toolbar="customToolbar" @blur="modifyConventionHonoraires"/>
        </CCol>
      </CRow>
    </div>


    <div class="mt-4">
      <CRow>
        <CCol>
          <h2 class="convention-title">Préambule</h2>
        </CCol>
        <CCol class="text-right align-self-center">
          <CButton
            v-c-tooltip="'Enregistrer'"
            square
            @click="modifyConventionHonoraires">
            <CIcon class="text-primary" size="lg" name="cil-save"/>
          </CButton>
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <vue-editor v-model="convention.preambule" :editor-toolbar="customToolbar" @blur="modifyConventionHonoraires"/>
        </CCol>
      </CRow>
    </div>


    <CRow class="mt-4">
      <CCol>
        <h2 style="font-size: 1.6rem !important; text-decoration: underline;"> Articles de votre convention </h2>
      </CCol>
    </CRow>

    <div v-for="article in convention.articles" :key="article.id" class="mt-4">
      <CRow>
        <CCol>
          <CInput
            size="lg"
            autocomplete="eclerk-no-autocomplete-input"
            v-model="article.title" type="text"
            maxlength="200"
            placeholder="Titre de votre article"
            invalid-feedback="Votre titre doit contenir entre 2 et 200 caractères"
            @blur="modifyConventionHonorairesArticle(article)">

            <template #prepend-content>Article {{article.order}}</template>
          </CInput>
        </CCol>
        <CCol class="text-right align-self-center">
          <CButton
            v-c-tooltip="'Enregistrer'"
            square
            @click="modifyConventionHonorairesArticle(article)">
            <CIcon class="text-primary" size="lg" name="cil-save"/>
          </CButton>
          <CButton
            v-if="article.order != 1"
            v-c-tooltip="'Monter'"
            square
            :disabled="isMovingConventionItem"
            @click="ascendConventionHonorairesArticle(article)">
            <CIcon class="text-dark" size="lg" name="cil-arrow-circle-top"/>
          </CButton>
          <CButton
            v-if="article.order != convention.articles.length"
            class="ml-1"
            v-c-tooltip="'Descendre'"
            square
            :disabled="isMovingConventionItem"
            @click="descendConventionHonorairesArticle(article)">
            <CIcon class="text-dark" size="lg" name="cil-arrow-circle-bottom"/>
          </CButton>
          <CButton
            class="ml-1"
            v-c-tooltip="'Supprimer'"
            square
            :disabled="isDeletingConventionItem"
            @click="deleteConventionHonorairesArticle(article)">
            <CIcon class="text-danger" size="lg" name="cil-trash"/>
          </CButton>
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <vue-editor v-model="article.body" :editor-toolbar="customToolbar" @blur="modifyConventionHonorairesArticle(article)"/>
        </CCol>
      </CRow>
    </div>


    <CRow class="mt-4">
      <CCol>
        <h2 style="font-size: 1.6rem !important; text-decoration: underline;" class="text-success"> Ajouter un nouvel article </h2>
      </CCol>
    </CRow>

    <CRow class="mt-2">
      <CCol md="6">
        <CInput
          size="lg"
          autocomplete="eclerk-no-autocomplete-input"
          v-model="newArticleTitle" type="text"
          maxlength="200"
          placeholder="Titre de votre nouvel article"
          @input="$v.newArticleTitle.$touch()"
          :isValid="$v.newArticleTitle.$dirty ? !$v.newArticleTitle.$error : null"
          invalid-feedback="Votre titre doit contenir entre 2 et 200 caractères"
        >
        </CInput>
      </CCol>
      <CCol md="6">
        <CButton
          @click="createConventionHonorairesArticle"
          shape="pill"
          block
          size="lg"
          color="outline-success"
          :disabled="$v.newArticleTitle.$invalid || isCreatingConventionItem">
            <CIcon name="cil-plus"/> Ajouter un article
        </CButton>
      </CCol>
    </CRow>

    <hr>


    <div class="mt-4">
      <CRow>
        <CCol>
          <h2 class="convention-title">Signature</h2>
        </CCol>
        <CCol class="text-right align-self-center">
          <CButton
            v-c-tooltip="'Enregistrer'"
            square
            @click="modifyConventionHonoraires">
            <CIcon class="text-primary" size="lg" name="cil-save"/>
          </CButton>
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <vue-editor v-model="convention.signature" :editor-toolbar="customToolbar" @blur="modifyConventionHonoraires"/>
        </CCol>
      </CRow>
    </div>


    <CRow class="mt-4">
      <CCol>
        <h2 style="font-size: 1.6rem !important; text-decoration: underline;"> Annexes de votre convention </h2>
      </CCol>
    </CRow>


    <div v-for="annexe in convention.annexes" :key="annexe.id" class="mt-4">
      <CRow>
        <CCol>
          <CInput
            size="lg"
            autocomplete="eclerk-no-autocomplete-input"
            v-model="annexe.title" type="text"
            maxlength="200"
            placeholder="Titre de votre article"
            invalid-feedback="Votre titre doit contenir entre 2 et 200 caractères"
            @blur="modifyConventionHonorairesAnnexe(annexe)">

            <template #prepend-content>Annexe {{annexe.order}}</template>
          </CInput>
        </CCol>
        <CCol class="text-right align-self-center">
          <CButton
            v-c-tooltip="'Enregistrer'"
            square
            @click="modifyConventionHonorairesAnnexe(annexe)">
            <CIcon class="text-primary" size="lg" name="cil-save"/>
          </CButton>
          <CButton
            v-if="annexe.order != 1"
            v-c-tooltip="'Monter'"
            square
            @click="ascendConventionHonorairesAnnexe(annexe)">
            <CIcon class="text-dark" size="lg" name="cil-arrow-circle-top"/>
          </CButton>
          <CButton
            v-if="annexe.order != convention.annexes.length"
            class="ml-1"
            v-c-tooltip="'Descendre'"
            square
            @click="descendConventionHonorairesAnnexe(annexe)">
            <CIcon class="text-dark" size="lg" name="cil-arrow-circle-bottom"/>
          </CButton>
          <CButton
            class="ml-1"
            v-c-tooltip="'Supprimer'"
            square
            :disabled="isDeletingConventionItem"
            @click="deleteConventionHonorairesAnnexe(annexe)">
            <CIcon class="text-danger" size="lg" name="cil-trash"/>
          </CButton>
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <vue-editor v-model="annexe.body" :editor-toolbar="customToolbar" @blur="modifyConventionHonorairesAnnexe(annexe)"/>
        </CCol>
      </CRow>
    </div>

    <CRow class="mt-4">
      <CCol>
        <h2 style="font-size: 1.6rem !important; text-decoration: underline;" class="text-success"> Ajouter une nouvelle annexe </h2>
      </CCol>
    </CRow>

    <CRow class="mt-4">
      <CCol md="6">
        <CInput
          size="lg"
          autocomplete="eclerk-no-autocomplete-input"
          v-model="newAnnexeTitle" type="text"
          maxlength="200"
          placeholder="Titre de votre nouvelle annexe"
          @input="$v.newAnnexeTitle.$touch()"
          :isValid="$v.newAnnexeTitle.$dirty ? !$v.newAnnexeTitle.$error : null"
          invalid-feedback="Votre titre doit contenir entre 2 et 200 caractères"
        >
        </CInput>
      </CCol>
      <CCol md="6">
        <CButton
          @click="createConventionHonorairesAnnexe"
          shape="pill"
          block
          size="lg"
          color="outline-success"
          :disabled="$v.newAnnexeTitle.$invalid || isCreatingConventionItem">
            <CIcon name="cil-plus"/> Ajouter une annexe
        </CButton>
      </CCol>
    </CRow>


    <div class="mt-4">
      <CRow>
        <CCol>
          <h2 class="convention-title">Bas de page - Sur chaque page</h2>
        </CCol>
        <CCol class="text-right align-self-center">
          <CButton
            v-c-tooltip="'Enregistrer'"
            square
            @click="modifyConventionHonoraires">
            <CIcon class="text-primary" size="lg" name="cil-save"/>
          </CButton>
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <vue-editor v-model="convention.footer" :editor-toolbar="customToolbar" @blur="modifyConventionHonoraires"/>
        </CCol>
      </CRow>
    </div>


    <CRow class="mt-4">
      <CCol>
        <CButton block color="outline-primary" @click="downloadConvention" shape="pill"><CIcon name="cil-cloud-download" /> Télécharger votre modèle de convention en pdf</CButton>
      </CCol>
    </CRow>

  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import { APIManagerConnected } from '@/api/APIManagerConnected'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength} from 'vuelidate/lib/validators'


const apiManagerConnected = new APIManagerConnected()

export default {
  name: 'ConventionHonoraires',
  components: {
    VueEditor,
    Loading
  },
  mixins: [
    validationMixin,
  ],
  data: function() {
    const today = new Date()
    return {
      today: today,

      isConventionLoading: false,
      isDeletingConventionItem: false,
      isMovingConventionItem: false,
      isCreatingConventionItem: false,

      preventNewSaving: false,
      savedConventionToasts: 0,

      convention: {
        introduction: '',
        preambule: '',
        signature: '',
        footer: '',
        articles: [],
        annexes: [],

        mission: {
          id: 0,
          name: '',
          client: {
            name: '',
            is_different_payeur: false,
            payeur_name: ''
          },
          types: [],
          state: '',
          montant_ht: '',
          starting_date: '',
          ending_date: '',
          description: '',
          is_active: '',
          created_at: '',

          contact: {
            id: 0,
            first_name: '',
            last_name: '',
            full_name: '',
          },
          referent: {
            id: 0,
            full_name: '',
            user: {
              id: 0,
              first_name: '',
              last_name: '',
              username: ''
            }
          },
          collaborateurs: [],
          convention_pk: null
        },
      },

      newArticleTitle: '',
      newAnnexeTitle: '',

      customToolbar: [
        [{ font: [] }],
        [{ header: [false,  2, 3, 4] }],
        ["bold", "italic", "underline", "strike"],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],

        ["blockquote" ],

        [{ list: "ordered" }, { list: "bullet" }],

        [{ script: "sub" }, { script: "super" }],

        [{ indent: "-1" }, { indent: "+1" }],

        [{ color: [] }, { background: [] }],

        ["clean"]

      ]
    }
  },

  validations: {
    newArticleTitle: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(200)
    },
    newAnnexeTitle: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(200)
    },
  },


  computed: {
    token () {
      return this.$store.state.auth.token
    },
    isLoading () {
      if (this.isConventionLoading || this.isDeletingConventionItem || this.isMovingConventionItem || this.isCreatingConventionItem) {
        return true
      }
      return false
    },
  },
  created () {
   this.getConventionHonoraires()
  },


  methods: {

    getConventionHonoraires () {
      this.isConventionLoading = true
      apiManagerConnected.getConventionHonoraires(this.token, this.$route.params.id)
      .then((result) => {
        this.convention = result.data
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isConventionLoading = false
      })
    },

    modifyConventionHonoraires () {
      if (this.preventNewSaving) {
        return false
      }
      this.preventNewSaving = true
      apiManagerConnected.modifyConventionHonoraires(
        this.token, this.convention
      )
      .then(() =>{
        this.savedConventionToasts ++
        setTimeout(() => this.preventNewSaving = false, 5000);
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
    },

    downloadConvention () {
      apiManagerConnected.downloadConventionHonorairesAsPdf(this.token, this.convention.id)
      .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          const conventionName = 'Convention-' + this.convention.mission.client.name + '.pdf'
          link.setAttribute('download', conventionName); //or any other extension
          document.body.appendChild(link);
          link.click();
        }, () => {
          this.$store.commit('openGlobalErrorModal')
        })
    },


    // ----- convention articles -----------

    createConventionHonorairesArticle () {
      this.isCreatingConventionItem = true
      apiManagerConnected.createConventionHonorairesArticle(
        this.token, this.convention, this.newArticleTitle
      )
      .then((result) =>{
        this.convention.articles.push(result.data)
        this.newArticleTitle = ''
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isCreatingConventionItem = false
      })
    },

    modifyConventionHonorairesArticle (article) {
      if (article.title == '') {
        return false
      }
      else {
        if (this.preventNewSaving) {
          return false
        }
        this.preventNewSaving = true
        apiManagerConnected.modifyConventionHonorairesArticle(
          this.token, article
        )
        .then(() =>{
          this.savedConventionToasts ++
          setTimeout(() => this.preventNewSaving = false, 5000);
        })
        .catch(() => {
          this.$store.commit('openGlobalErrorModal')
        })
      }
    },

    deleteConventionHonorairesArticle (article) {
      this.isDeletingConventionItem = true
      apiManagerConnected.deleteConventionHonorairesArticle(
        this.token, article
      )
      .then(() =>{
        this.getConventionHonoraires()
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isDeletingConventionItem = false
      })
    },

    ascendConventionHonorairesArticle (article) {
      this.isMovingConventionItem = true
      apiManagerConnected.ascendConventionHonorairesArticle(
        this.token, article.id
      )
      .then(() =>{
        this.getConventionHonoraires()
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isMovingConventionItem = false
      })
    },

    descendConventionHonorairesArticle (article) {
      this.isMovingConventionItem = true
      apiManagerConnected.descendConventionHonorairesArticle(
        this.token, article.id
      )
      .then(() =>{
        this.getConventionHonoraires()
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isMovingConventionItem = false
      })
    },



    // ----- convention annexes -----------

    createConventionHonorairesAnnexe () {
      this.isCreatingConventionItem = true
      apiManagerConnected.createConventionHonorairesAnnexe(
        this.token, this.convention, this.newAnnexeTitle
      )
      .then((result) =>{
        this.convention.annexes.push(result.data)
        this.newAnnexeTitle = ''
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isCreatingConventionItem = false
      })
    },

    modifyConventionHonorairesAnnexe (annexe) {
      if (annexe.title == '') {
        return false
      }
      else {
        if (this.preventNewSaving) {
          return false
        }
        this.preventNewSaving = true
        apiManagerConnected.modifyConventionHonorairesAnnexe(
          this.token, annexe
        )
        .then(() =>{
          this.savedConventionToasts ++
          setTimeout(() => this.preventNewSaving = false, 5000);
        })
        .catch(() => {
          this.$store.commit('openGlobalErrorModal')
        })
      }
    },

    deleteConventionHonorairesAnnexe (annexe) {
      this.isDeletingConventionItem = true
      apiManagerConnected.deleteConventionHonorairesAnnexe(
        this.token, annexe
      )
      .then(() =>{
        this.getConventionHonoraires()
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isDeletingConventionItem = false
      })
    },

    ascendConventionHonorairesAnnexe (annexe) {
      this.isMovingConventionItem = true
      apiManagerConnected.ascendConventionHonorairesAnnexe(
        this.token, annexe.id
      )
      .then(() =>{
        this.getConventionHonoraires()
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isMovingConventionItem = false
      })
    },

    descendConventionHonorairesAnnexe (annexe) {
      this.isMovingConventionItem = true
      apiManagerConnected.descendConventionHonorairesAnnexe(
        this.token, annexe.id
      )
      .then(() =>{
        this.getConventionHonoraires()
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isMovingConventionItem = false
      })
    },
  }
}

</script>
<style>
.toast {
  flex-basis: auto !important;
}
.custom-toast {
  background-color: white;
}
</style>
